import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
    components: {
        Banner
    },
    props: {
        home: {
            type: Boolean,
            default: false
          }
    },
    data() {
        return {
            mostrar: 'div0',
            cor: "",
            strTitulo:
                "<span class='nome'>Passo a Passo</span>"
        };
    },
    
    methods: {
        textoAtivo(val, cores) {
            this.mostrar = val;
            this.cor = cores
        },
        mostrarTexto(val) {
            return this.mostrar === val;
        },
        
    }
};
